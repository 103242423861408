import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  padding: ${getSpacing(7)} ${getSpacing(10)};
`;

export const Footer = styled.div<{ fixedFooter?: boolean }>`
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${props =>
    props.fixedFooter &&
    `position: absolute; bottom: 0; left: 0; right: 0; margin-top: 0; border-top: 1px solid ${
      defaultTheme.colors.separator
    }; padding: ${getSpacing(4)}`}
`;

export const Back = styled.p<{ fixedFooter?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  ${props => props.fixedFooter && `margin-left: ${getSpacing(4)};`}
  :hover {
    cursor: pointer;
  }
`;

export const FieldContent = styled.div`
  min-height: 100px;
`;

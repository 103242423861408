import styled from 'styled-components';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { getSpacing } from 'stylesheet';

export const Styles = {
  Wrapper: styled.div`
    position: relative;
  `,
  StyledInput: styled.input<{ isError?: boolean }>`
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.deepBlue};
    border: 1px solid
      ${props => (props.isError ? defaultTheme.colors.alertRed : defaultTheme.colors.separator)};
    padding: ${getSpacing(4)};
    border-radius: 4px;
    ::placeholder {
      color: ${({ theme }) => theme.colors.darkGrey};
    }

    :disabled:hover {
      cursor: not-allowed;
    }
  `,
};

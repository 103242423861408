import { DropdownValue } from 'models/global';
import React, { useState } from 'react';
import Select, { Options } from 'react-select';
import { multiSelectStyles } from './styles';
import { Error } from 'atoms/Error';

export type MultiSelectProps = {
  options: Options<DropdownValue>;
  handleSelect: (selectedOption: Options<DropdownValue>) => void;
  name: string;
  defaultValue?: Options<DropdownValue>;
  maxLimit?: number;
  value?: Options<DropdownValue>;
  disabled?: boolean;
  maxMenuHeight?: number;
  closeMenuOnSelect?: boolean;
  placeholder?: string;
  error?: string | string[];
};

type ReactSelectActions =
  | 'select-option'
  | 'deselect-option'
  | 'remove-value'
  | 'pop-value'
  | 'set-value'
  | 'clear'
  | 'create-option';

export type SelectedOption = {
  action: ReactSelectActions;
};

export function createOnChangeHandler(
  maxLimit: number | undefined,
  maxReached: boolean,
  setMaxReached: (value: ((prevState: boolean) => boolean) | boolean) => void,
  handleSelect: (selectedOption: Options<DropdownValue>) => void,
) {
  return (selectedOptions: Options<DropdownValue>, { action }: SelectedOption): void => {
    if (maxLimit) {
      if (action === 'select-option' && maxReached) {
        return;
      }

      if (action === 'select-option' && selectedOptions.length === maxLimit) {
        setMaxReached(true);
      } else {
        setMaxReached(false);
      }

      return handleSelect(selectedOptions);
    }

    return handleSelect(selectedOptions);
  };
}

export function createNoOptionsFunc(
  maxReached: boolean,
  maxLimit: number | undefined,
  noOptionsMessage?: string,
): () => string {
  const msg = noOptionsMessage ?? 'No options';
  return () =>
    maxReached ? `You can only select ${maxLimit} ${maxLimit === 1 ? 'option' : 'options'}` : msg;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  handleSelect,
  options,
  name,
  defaultValue,
  maxLimit,
  value,
  disabled,
  maxMenuHeight,
  closeMenuOnSelect,
  placeholder,
  error,
}) => {
  const [maxReached, setMaxReached] = useState(false);

  const handleOnChange = createOnChangeHandler(maxLimit, maxReached, setMaxReached, handleSelect);
  const noOptionsFunc = createNoOptionsFunc(maxReached, maxLimit);

  return (
    <div data-testid={`select_${name}`}>
      <Select
        isDisabled={disabled}
        styles={multiSelectStyles}
        hideSelectedOptions
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        isMulti
        closeMenuOnSelect={closeMenuOnSelect || false}
        noOptionsMessage={noOptionsFunc}
        defaultValue={defaultValue || null}
        name={name}
        value={value}
        onChange={(selectedOptions, other: SelectedOption) => {
          handleOnChange(selectedOptions, other);
        }}
        options={maxLimit && maxReached ? [] : options}
        maxMenuHeight={maxMenuHeight}
        placeholder={placeholder}
      />
      {error && <Error marginTop="0">{Array.isArray(error) ? error.join(',') : error}</Error>}
    </div>
  );
};

export default MultiSelect;

import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.separator};
  resize: none;
  border-radius: ${defaultTheme.spacing(1)};
  padding: ${defaultTheme.spacing(2)};
  font-size: 14px;
  line-height: 21px;

  :disabled:hover {
    cursor: not-allowed;
  }
`;

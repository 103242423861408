import React, { useEffect, useMemo } from 'react';
import MultiSelect from 'atoms/MultiSelect';
import { Button } from 'atoms/Button/Button';
import { Field } from 'atoms/Field/Field';
import { Header } from 'atoms/Header/Header';
import { Wrapper, Footer, Back, FieldContent } from './styles';
import { useFormik } from 'formik';
import { AddAOSFormSchema } from './AddAOSForm.schema';
import { getRelevantAOPs, handleSelect } from './AddAOSForm.helpers';
import { formatForDropdown } from 'utils/formatForDropdown/formatForDropdown';
import { ClinicalAttribute, DropdownValue } from 'models/global';
import { ItemInput } from 'models/itemInput';
import { TEST_IDS } from 'constants/testIds';
import { Options } from 'react-select';
import { AreaOfSpecialisation } from '../../../../types/graphql';

export type AOSFormValues = {
  aos: DropdownValue[];
  productGroups: DropdownValue[];
};

// Can remove once types have been refactored
export type AosDataType = AreaOfSpecialisation | ClinicalAttribute;

export type AddAOSFormProps = {
  currentAosValues: ItemInput[];
  onBackClick: () => void;
  setValues: (values: AOSFormValues) => void;
  aosData: AosDataType[];
  areasOfPracticeIds?: string[];
  editAos?: AOSFormValues | null;
  fixedFooter?: boolean;
  isEmployee?: boolean;
  disabled?: boolean;
};

const getInitialValues = (initialValues?: AOSFormValues | null): AOSFormValues => {
  if (initialValues) {
    return {
      aos: initialValues.aos,
      productGroups: initialValues.productGroups,
    };
  }

  return {
    aos: [],
    productGroups: [],
  };
};

export const AddAOSForm: React.FC<AddAOSFormProps> = ({
  onBackClick,
  setValues,
  currentAosValues,
  editAos,
  aosData,
  areasOfPracticeIds,
  fixedFooter,
  isEmployee,
  disabled,
}) => {
  const { handleSubmit, isSubmitting, isValid, setFieldValue, values } = useFormik({
    initialValues: getInitialValues(editAos),
    onSubmit: values => {
      setValues(values);
      onBackClick();
    },
    validationSchema: AddAOSFormSchema,
  });

  // Clears product groups if aos is empty
  useEffect(() => {
    if (!values.aos.length) {
      setFieldValue('productGroups', []);
    }
  }, [values.aos]);

  const aosOptions = useMemo(() => {
    const ids = currentAosValues.map(i => i.id);
    const ops = aosData.filter((i: AosDataType) => !ids.includes(i.id));
    return formatForDropdown(ops);
  }, [aosData, currentAosValues]);

  return (
    <Wrapper>
      <Field>
        <FieldContent>
          <Header
            title="Area of specialization"
            subtitle={
              isEmployee
                ? 'Select the area of specialization this employee sells to'
                : 'Select the area of specialization for the newsfeed post'
            }
            disabled={!!editAos || disabled}
          />
          <MultiSelect
            closeMenuOnSelect
            maxLimit={1}
            options={aosOptions}
            disabled={!!editAos || disabled}
            handleSelect={selectedOptions =>
              handleSelect(setFieldValue, selectedOptions as DropdownValue[], aosOptions, 'aos')
            }
            value={values.aos}
            name="areasOfSpecialisation"
          />
        </FieldContent>
      </Field>
      <Field>
        <FieldContent>
          <Header
            title="Product Groups"
            subtitle={
              isEmployee
                ? 'The product type that will be sold to clinicians'
                : 'Select the product groups for the newsfeed post'
            }
            disabled={(!!!values.aos.length && !editAos) || disabled}
          />
          <MultiSelect
            options={getRelevantAOPs(values.aos, aosData, areasOfPracticeIds)}
            handleSelect={selectedOptions =>
              handleSelect(
                setFieldValue,
                selectedOptions as DropdownValue[],
                getRelevantAOPs(values.aos, aosData),
                'productGroups',
              )
            }
            name="areasOfPractice"
            disabled={(!!!values.aos.length && !editAos) || disabled}
            value={values.productGroups as unknown as Options<DropdownValue>}
          />
        </FieldContent>
      </Field>
      <Footer fixedFooter={fixedFooter}>
        <Back onClick={onBackClick} fixedFooter={fixedFooter}>
          Back
        </Back>
        <Button
          type="submit"
          text={isSubmitting ? 'Loading...' : 'Save area of specialty'}
          disabled={
            isSubmitting ||
            !isValid ||
            !values.aos.length ||
            !!(editAos && areAopsSame(editAos.productGroups, values.productGroups)) ||
            disabled
          }
          data-testid={TEST_IDS.addEmployeeModal.confirmAosButton}
          onClick={e => handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}
        />
      </Footer>
    </Wrapper>
  );
};

const areAopsSame = (initial: DropdownValue[], changed: DropdownValue[]) => {
  return JSON.stringify(initial) === JSON.stringify(changed);
};
